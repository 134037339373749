import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const Header = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/social`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div id="loading" className="preloader-wrap">
                <div className="preloader-2 text-center">
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                    <span className="line line-4"></span>
                    <span className="line line-5"></span>
                    <span className="line line-6"></span>
                    <span className="line line-7"></span>
                    <span className="line line-8"></span>
                    <span className="line line-9"></span>
                    <div className="loader-text">Loading ...</div>
                </div>
            </div>

            <div className="back-to-top-wrapper">
                <button id="back_to_top" type="button" className="back-to-top-btn">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6L6 1L1 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            <div className="tp-offcanvas-area">
                <div className="tp-offcanvas-wrapper">
                    <div className="tp-offcanvas-top d-flex align-items-center justify-content-between">
                        <div className="tp-offcanvas-logo">
                            <a href="index.html">
                                <img className="logo-1" src="assets/img/logo/logo.png" alt="" />
                                <img className="logo-2" src="assets/img/logo/logo-white.png" alt="" />
                            </a>
                        </div>
                        <div className="tp-offcanvas-close">
                            <button className="tp-offcanvas-close-btn">
                                <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.19141 9.80762L27.5762 28.1924" stroke="currentColor" strokeWidth="1.5"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.19141 28.1924L27.5762 9.80761" stroke="currentColor" strokeWidth="1.5"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="tp-offcanvas-main">
                        <div className="tp-offcanvas-content">
                            <h3 className="tp-offcanvas-title">Hello There!</h3>
                        </div>
                        <div className="tp-main-menu-mobile d-xl-none"></div>
                        <div className="tp-offcanvas-contact">
                            <h3 className="tp-offcanvas-title sm">Information</h3>

                            <ul>
                                <li><a href={`tel:+91${data.number}`}>+91 {data.number}</a></li>
                                <li><a href={`mailto:${data.email}`}>{data.email}</a></li>
                                <li><a href="#">{data.address}</a></li>
                            </ul>
                        </div>
                        <div className="tp-offcanvas-social">
                            <h3 className="tp-offcanvas-title sm">Follow Us</h3>
                            <ul>
                                <li>
                                    <a
                                        href={`https://www.instagram.com/${data.instagram}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>

                                </li>
                                <li>
                                    <a href={`https://www.linkedin.com/company/${data.linkedin}`} target="_blank"
                                        rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="body-overlay"></div>

           

            <header>
                <div id="header-sticky" className="tp-header-3-area mt-35 z-index-5">
                    <span className="menu-bg"></span>
                    <div className="container container-1740">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-6">
                                <div className="tp-header-logo tp-header-3-logo">
                                    <a className="logo-1" href="index.html"><img src="assets/img/logo/logo.png" alt="" /></a>
                                    <a className="logo-2" href="index.html"><img src="assets/img/logo/logo-white.png" alt="" /></a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 d-none d-xl-block">
                                <div className="tp-header-3-menu-wrap text-center">
                                    <div className="tp-header-3-menu-box d-inline-flex align-items-center justify-content-between">
                                        <div className="tp-header-3-menu header-main-menu">
                                            <nav className="tp-main-menu-content">
                                                <ul>
                                                    <li className="has-dropdown">
                                                        <a href="/">Home</a>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <a href="/aboutus">About Us</a>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <a href="#portfolio">Portfolio</a>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <a href="/services">Our Services</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-6">
                                <div className="tp-header-3-right d-flex align-items-center justify-content-end">
                                    <div className="tp-header-3-social d-none d-sm-block">
                                        <a
                                            href={`https://www.instagram.com/${data.instagram}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa-brands fa-instagram"></i>
                                        </a>
                                        <a href={`https://www.linkedin.com/in/${data.linkedin}`} target="_blank"
                                            rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                                    </div>
                                    <button className="tp-header-3-bar tp-offcanvas-open-btn d-xl-none"><i className="fa-solid fa-bars"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
