import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config';

const BrandListAboutus = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchAboutUsBrands = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/brandformforaboutus`);
                setBrands(response.data);
            } catch (error) {
                console.error('Error fetching About Us brands:', error);
            }
        };

        fetchAboutUsBrands();
    }, []);

    return (
        <div className="tp-team-area pb-120 fix">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-team-slider-wrapper">
                            <div className="swiper-container tp-team-slider-active">
                                <div className="swiper-wrapper">
                                    {brands.map((brand) => (
                                        <div className="swiper-slide" key={brand.id}>
                                            <div className="tp-team-item tp-hover-btn-wrapper marque fix mb-30">
                                                <div className="tp-hover-btn-item">
                                                    <img
                                                        src={`${config.API_BASE_URL_IMAGE}${brand.image}`}  // Direct access to the image
                                                        alt={`Brand ${brand.name}`}
                                                        style={{ width: '380px', height: '470px', margin: '5px' }}
                                                    />
                                                </div>
                                                <div className="tp-team-content">
                                                    
                                                    <h4 className="tp-team-title-sm">
                                                        <a href="#">{brand.name}</a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandListAboutus;
