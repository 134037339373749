import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path to your config file

const ServicesSection = () => {
    const [servicesData, setServicesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch data
        const fetchServicesData = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/services`);
                setServicesData(response.data); // assuming response.data is an array of service objects
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch services data');
                setLoading(false);
            }
        };

        fetchServicesData();
    }, []); // Empty dependency array ensures this runs only once on mount

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="sv-service-area project-panel-area-2">
            <div className="container-fluid p-0">
                {servicesData.map(service => (
                    <div key={service.id} className="sv-service-item project-panel-2">
                        <div className="row g-0">
                            <div className="col-xl-6 col-lg-6">
                                <div className="sv-service-thumb">
                                <img src={`${config.API_BASE_URL_IMAGE}${service.image}`} alt={service.title} />

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="sv-service-content-wrap d-flex align-items-center">
                                    <div className="sv-service-content">
                                        <div className="sv-service-title-box">
                                            <span className="sv-service-subtitle"><i>{service.id}</i> {service.subtitle}</span>
                                            <h4 className="sv-service-title">{service.title}</h4>
                                        </div>
                                        <div className="sv-service-space-wrap">
                                            <div className="sv-service-text">
                                                <p>{service.description}</p>
                                            </div>
                                            <div className="sv-service-list">
                                             <ul>
                                                 {(Array.isArray(service.features) ? service.features : JSON.parse(service.features)).map((feature, index) => (
                                                 <li key={index}>{feature}</li>
                                                 ))}
                                             </ul>
                                            </div>
                                            {/* <div className="sv-service-btn">
                                                <a className="tp-btn-zikzak zikzak-inner p-relative" href={service.link}>
                                                    <span className="zikzak-content">
                                                        See <br /> Details
                                                    </span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesSection;
